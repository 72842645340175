import * as i0 from '@angular/core';
import { Injectable, Inject, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import * as i1$1 from 'ngx-logger';
import { formatNumber, CommonModule } from '@angular/common';
import { filter } from 'rxjs/operators';
import * as i1$2 from '@ngneat/transloco';
import * as i1$3 from '@angular/router';
class HttpAdapterService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  post(url, body, options) {
    return this.httpClient.post(url, body, options);
  }
  put(url, body, options) {
    return this.httpClient.put(url, body, options);
  }
  patch(url, body, options) {
    return this.httpClient.patch(url, body, options);
  }
  get(url, options) {
    return this.httpClient.get(url, options);
  }
  getBlob(url, options) {
    return this.httpClient.get(url, {
      headers: options?.headers,
      params: options?.params,
      responseType: 'blob',
      observe: 'response',
      withCredentials: options?.withCredentials
    });
  }
  getArrayBuffer(url, options) {
    return this.httpClient.get(url, {
      headers: options?.headers,
      params: options?.params,
      responseType: 'arraybuffer',
      observe: 'response',
      withCredentials: options?.withCredentials
    });
  }
  delete(url, options) {
    return this.httpClient.delete(url, options);
  }
  static {
    this.ɵfac = function HttpAdapterService_Factory(t) {
      return new (t || HttpAdapterService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpAdapterService,
      factory: HttpAdapterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpAdapterService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }], null);
})();

// Angular
class LoggerAdapterService {
  constructor(logger) {
    this.logger = logger;
  }
  info(className, methodName, message) {
    const formattedMessage = this.formatLoggerMessage(className, methodName, message);
    this.logger.info(formattedMessage);
  }
  debug(className, methodName, message) {
    const formattedMessage = this.formatLoggerMessage(className, methodName, message);
    this.logger.debug(formattedMessage);
  }
  warn(className, methodName, message) {
    const formattedMessage = this.formatLoggerMessage(className, methodName, message);
    this.logger.warn(formattedMessage);
  }
  error(className, methodName, message) {
    const formattedMessage = this.formatLoggerMessage(className, methodName, message);
    this.logger.error(formattedMessage);
  }
  formatLoggerMessage(className, methodName, message) {
    return `[${className}](${methodName}) ${message}`;
  }
  static {
    this.ɵfac = function LoggerAdapterService_Factory(t) {
      return new (t || LoggerAdapterService)(i0.ɵɵinject(i1$1.NGXLogger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoggerAdapterService,
      factory: LoggerAdapterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoggerAdapterService, [{
    type: Injectable
  }], () => [{
    type: i1$1.NGXLogger
  }], null);
})();
class TranslocoAdapterService {
  constructor(transloco) {
    this.transloco = transloco;
  }
  events() {
    return this.transloco.events$;
  }
  getActiveLang() {
    return this.transloco.getActiveLang();
  }
  getDefaultLang() {
    return this.transloco.getDefaultLang();
  }
  getTranslation(langOrScope) {
    return this.transloco.getTranslation(langOrScope);
  }
  langChanges() {
    return this.transloco.langChanges$;
  }
  load(path) {
    return this.transloco.load(path);
  }
  selectTranslate(key, params, lang) {
    return this.transloco.selectTranslate(key, params, lang);
  }
  selectTranslateObject(key, params, lang) {
    return this.transloco.selectTranslateObject(key, params, lang);
  }
  setActiveLang(lang) {
    return this.transloco.setActiveLang(lang);
  }
  translocoEvents() {
    return this.transloco.events$;
  }
  translationLoadSuccessObs(scope) {
    if (!scope) {
      return this.transloco.events$.pipe(filter(e => e.type === 'translationLoadSuccess'));
    }
    return this.transloco.events$.pipe(filter(e => e.type === 'translationLoadSuccess' && e.payload.scope === scope));
  }
  translate(key, params, lang) {
    return this.transloco.translate(key, params, lang);
  }
  translateObject(key, params, lang) {
    return this.transloco.translateObject(key, params, lang);
  }
  static {
    this.ɵfac = function TranslocoAdapterService_Factory(t) {
      return new (t || TranslocoAdapterService)(i0.ɵɵinject(i1$2.TranslocoService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslocoAdapterService,
      factory: TranslocoAdapterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoAdapterService, [{
    type: Injectable
  }], () => [{
    type: i1$2.TranslocoService
  }], null);
})();

// Angular
class NumberAdapterService {
  constructor(translocoAdapterService) {
    this.translocoAdapterService = translocoAdapterService;
  }
  formatNumberCurrency(value, currency, locale, digitsInfo) {
    const tmpLocale = !locale ? this.translocoAdapterService.getActiveLang() : locale;
    const tmpDigitsInfo = !digitsInfo ? '1.2-2' : digitsInfo;
    const numberFormatted = formatNumber(value, tmpLocale, tmpDigitsInfo);
    const numberCurrencyFormatted = `${numberFormatted} ${currency}`;
    return numberCurrencyFormatted;
  }
  static {
    this.ɵfac = function NumberAdapterService_Factory(t) {
      return new (t || NumberAdapterService)(i0.ɵɵinject(TranslocoAdapterService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NumberAdapterService,
      factory: NumberAdapterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberAdapterService, [{
    type: Injectable
  }], () => [{
    type: TranslocoAdapterService
  }], null);
})();

// Angular
class RouterAdapterService {
  constructor(adaptersConfig, router) {
    this.router = router;
    if (adaptersConfig && adaptersConfig.router && adaptersConfig.router.shouldReuseRoute === false) {
      // reload same route with different params
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };
    }
  }
  navigateByUrl(url) {
    this.router.navigateByUrl(url);
  }
  static {
    this.ɵfac = function RouterAdapterService_Factory(t) {
      return new (t || RouterAdapterService)(i0.ɵɵinject('adaptersConfig'), i0.ɵɵinject(i1$3.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RouterAdapterService,
      factory: RouterAdapterService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RouterAdapterService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['adaptersConfig']
    }]
  }, {
    type: i1$3.Router
  }], null);
})();
const adapters = [HttpAdapterService, LoggerAdapterService, NumberAdapterService, RouterAdapterService, TranslocoAdapterService];
var TranslocoEventEnum;
(function (TranslocoEventEnum) {
  TranslocoEventEnum["LANG_CHANGED"] = "langChanged";
  TranslocoEventEnum["TRANSLATION_LOAD_FAILURE"] = "translationLoadFailure";
  TranslocoEventEnum["TRANSLATION_LOAD_SUCCESS"] = "translationLoadSuccess";
})(TranslocoEventEnum || (TranslocoEventEnum = {}));

// Angular
class AdaptersModule {
  static forRoot(adaptersConfig) {
    return {
      ngModule: AdaptersModule,
      providers: [...adapters, {
        provide: 'adaptersConfig',
        useValue: adaptersConfig
      }]
    };
  }
  static {
    this.ɵfac = function AdaptersModule_Factory(t) {
      return new (t || AdaptersModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AdaptersModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AdaptersModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AdaptersModule, HttpAdapterService, LoggerAdapterService, NumberAdapterService, RouterAdapterService, TranslocoAdapterService, TranslocoEventEnum, adapters };
