// Angular
import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
} from '@angular/core';
// RxJs
import { Observable } from 'rxjs';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
import { currentYear } from '@atomics/common';
import { svgIconsConsts } from '@atomics/icons';
// Env
import { environment } from '@portfolio-mlv/environments';
// Portfolio MLV Core
import {
  langConsts,
  RootUIFooterTranslations,
} from '@portfolio-mlv/core/internationalization';

const FOOTER_TRANSLATIONS_KEY = 'ui.footer';

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Output() changeLocalization = new EventEmitter<string>();

  translations$: Observable<RootUIFooterTranslations> =
    this.translocoAdapterService.selectTranslateObject(FOOTER_TRANSLATIONS_KEY);

  appVersion = environment.version;
  langConsts = langConsts;

  svgIconsConsts = svgIconsConsts;

  currentYear = currentYear();

  constructor(private translocoAdapterService: TranslocoAdapterService) {}

  onChangeLocalization(lang: string) {
    this.changeLocalization.emit(lang);
  }
}
