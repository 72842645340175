// Angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// Transloco
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  exports: [TranslocoModule],
})
export class TranslocoChildModule {
  static forChild(scope: string): ModuleWithProviders<TranslocoChildModule> {
    return {
      ngModule: TranslocoChildModule,
      providers: [{ provide: TRANSLOCO_SCOPE, useValue: scope }],
    };
  }
}
