// NgRx
import { createSelector, createFeatureSelector } from '@ngrx/store';
// Atomics libs
import { getErrors, LoadingState } from '@atomics/common';
// Portfolio MLV Landing (current module)
import { LandingPageVM } from '../types';
import { LandingState, LANDING_FEATURE_KEY } from './landing.reducer';

// Lookup the 'Landing' state managed by NgRx
export const getLandingState =
  createFeatureSelector<LandingState>(LANDING_FEATURE_KEY);

// Landing Page VM
export const selectLandingPageVM = createSelector((): LandingPageVM => ({}));
