// Angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
// Atomics libs
import { BtnIconModule, DialogLoadingModule, FooterModule, LinksModule, TextModule } from '@atomics/ui';
// Shared (current module)
import * as fromComponents from './components';
import * as fromAdapters from './adapters';

const atomicsUIModules = [BtnIconModule, DialogLoadingModule, FooterModule, LinksModule, TextModule];

@NgModule({
  imports: [CommonModule, ...atomicsUIModules],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...fromAdapters.adapters],
    };
  }
}
