// Angular
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
// RxJS
import { lastValueFrom } from 'rxjs';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
import { setGlobalLibsLocale } from '@atomics/common';
// Portfolio MLV Shared
import { appConstants } from '@portfolio-mlv/shared';

function preloadLocalization(translocoAdapterService: TranslocoAdapterService) {
  return () => {
    // App locale
    registerLocaleData(localeIt);

    // Set global locale for libraries
    setGlobalLibsLocale(appConstants.LOCALE);

    // Set transloco i18
    const defaultLang = translocoAdapterService.getDefaultLang();
    translocoAdapterService.setActiveLang(defaultLang);
    return lastValueFrom(translocoAdapterService.load(defaultLang));
  };
}

export const preLoad = [
  { provide: LOCALE_ID, useValue: appConstants.LOCALE },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: preloadLocalization,
    deps: [TranslocoAdapterService],
  },
];
