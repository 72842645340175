// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Atomics libs
import { PipesModule as AtomicsPipesModule } from '@atomics/common';
import {
  ButtonsModule,
  HeadingModule,
  LoadingModule,
  LogoModule,
  SvgModule,
  TextModule,
} from '@atomics/ui';
// Portfolio MLV Core
import {
  titlesKeys,
  TranslocoChildModule,
  scopesNames,
} from '@portfolio-mlv/core/internationalization';
// Portfolio MLV Shared
import { SharedModule as AppSharedModule } from '@portfolio-mlv/shared';
import { SharedPipesModule as AppSharedPipesModule } from '@portfolio-mlv/shared/pipes';
// Portfolio MLV Landing (current module)
import { LandingPageComponent, WorkPageComponent } from './containers';
// Portfolio MLV Landing
import { landingRoutesNames } from '@portfolio-mlv/landing';
import * as fromServices from './services';
import { LandingStoreModule } from './store';

// Group here Atomics UI modules
const atomicsUIModules = [
  ButtonsModule,
  HeadingModule,
  LoadingModule,
  LogoModule,
  SvgModule,
  TextModule,
];

const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: `${landingRoutesNames.LANDING}` },
  {
    path: `${landingRoutesNames.LANDING}`,
    component: LandingPageComponent,
    title: titlesKeys.LANDING,
  },
  {
    path: `work`,
    component: WorkPageComponent,
    title: titlesKeys.WORK,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    TranslocoChildModule.forChild(scopesNames.LANDING),
    ...atomicsUIModules,
    AtomicsPipesModule,
    AppSharedModule,
    AppSharedPipesModule,
    LandingStoreModule,
  ],
  declarations: [LandingPageComponent],
  providers: [...fromServices.services],
})
export class LandingModule { }
