<!-- <ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      landingPageVM: landingPageVM$ | async,
      translations: translations$ | async
    } as vm"
  >

  </ng-container>
</ng-container> -->

<div class="main">
  <div class="background">      
    <div id="stars"></div>
    <div id="stars2"></div>
    <div id="stars3"></div>
  </div>
  <div class="content">
    <div class="menu">
      <div class="left">
        <img src="assets/images/logo.svg" alt="">
      </div>
      <div class="right">
        <ul>
          <li>
            <a href="">
              <p>work</p>
            </a>
          </li>
          <li>
            <a href="">
              <p>cv</p>
            </a>
          </li>
          <li>
            <a href="">
              <p>connect</p>
            </a>
          </li>
        </ul>
      </div>
    </div>

        <!-- work -->
        <div class="work">
          <h1 class="title-max">work</h1>
          <!-- Acolytes -->
          <div class="work-content acolytes">
            <div class="header">
              <div class="left">
                <p class="overtitle">2019 - Today</p>
                <p class="role">Design Lead & UX Engineer</p>
                <p class="description">
                  Led the end-to-end product design and development handoffs for mobility companies. 
                </p>
                <p class="description">
                  Improving passenger travel with 15+ products in France, London, Barcelona, Rome, and Tuscany. 
                </p>
                <p class="description">
                  Cut project expenses by 40% using cost-effective design strategies and implementing design systems.
                </p>
              </div>
              <div class="right">
                <h6 class="company">acolytes</h6>
              </div>
            </div>
            <div class="media">
              <iframe width="560" height="315" 
                src="https://www.youtube.com/embed/EvbDOZybZUc?si=D9LjDH_O_Ww0pR8_;autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
              </iframe>
            </div>
            <div class="actions">
              <button class="primary">
                <!-- <div class="left">
                  <h6>learn more</h6>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8L18 12M18 12L14 16M18 12H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                </div> -->
                <div class="right">
                  <!-- <h6>let's go!</h6> -->
                  <h6>full case study - coming soon</h6>
                </div>
              </button>
            </div>
          </div>
          <!-- Infovista -->
          <div class="work-content infovista">
            <div class="header">
              <div class="left">
                <p class="overtitle">2021 - Today</p>
                <p class="role">UX Architect</p>
                <p class="description">
                  Established the design team, set up user experience, and design systems.  
                </p>
                <p class="description">
                  Implemented developer handoffs processes for effective collaboration reducing product delivery time by 35%. 
                </p>
                <p class="description">
                  Increased user engagement by 98% using the InfovistaDS, improving products design and achieving a stronger brand cohesion.
                </p>
              </div>
              <div class="right">
                <h6 class="company">infovista sas</h6>
              </div>
            </div>
            <div class="media">
              <img src="../../../../assets/images/macbook-infovista-dashboard.png" alt="Macbook Pro with a dark theme dashboard showcasing traffic monitoring data created for Infovista's customers">
            </div>
            <div class="actions">
              <button class="primary">
                <!-- <div class="left">
                  <h6>learn more</h6>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8L18 12M18 12L14 16M18 12H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                </div> -->
                <div class="right">
                  <!-- <h6>let's go!</h6> -->
                  <h6>full case study - coming soon</h6>
                </div>
              </button>
            </div>
          </div>
          <!-- AtomicsDS -->
          <div class="work-content atomics">
            <div class="header">
              <div class="left">
                <p class="overtitle">work and personal project</p>
                <p class="role">atomicsDS</p>
                <p class="description">
                  My personal design system for launching projects efficiently.  Maintains consistency, emphasizes scalability and brings me peace of mind in the design processes. 
                </p>
              </div>
              <div class="right">
              </div>
            </div>
            <div class="bento">
              <div class="left">
                <div class="box">
                  <p class="box__title left">Features</p>
                  <!-- 1st -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Saves 64% of design time</p>
                  </div>
                  <!-- 2nd -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">High adoption rate</p>
                  </div>
                  <!-- 3rd -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Dark + Light Mode</p>
                  </div>
                  <!-- 4th -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Multi-tenancy</p>
                  </div>
                  <!-- 5th -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Design Tokens</p>
                  </div>
                  <!-- 6th -->
                  <div class="line">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Detailed Documentation</p>
                  </div>
                  <!-- 7th -->
                  <div class="line desktop">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">UI + UX Checklists</p>
                  </div>
                  <!-- 8th -->
                  <div class="line desktop">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Focus on Accessibility</p>
                  </div>
                  <!-- 9th -->
                  <div class="line desktop">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Continuous development</p>
                  </div>
                  <!-- 10th -->
                  <div class="line desktop">
                    <div class="check">
                      <div class="inner">
                        <!-- check -->
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_498_17486)">
                          <path d="M7.81411 1.30692C7.56659 1.05907 7.16466 1.05923 6.9168 1.30692L2.8783 5.34559L1.08335 3.55066C0.835501 3.30281 0.433739 3.30281 0.185888 3.55066C-0.0619628 3.79851 -0.0619628 4.20027 0.185888 4.44812L2.42947 6.69171C2.55332 6.81555 2.71571 6.87763 2.87812 6.87763C3.04053 6.87763 3.20309 6.81571 3.32693 6.69171L7.81411 2.20437C8.06196 1.95669 8.06196 1.55476 7.81411 1.30692Z" fill="#171717"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_498_17486">
                          <rect width="8" height="8" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <p class="description">Sketch + Figma files</p>
                  </div>
                </div>
              </div>
              <div class="right">
                <!-- 1st -->
                <div class="box">
                  <div class="img-wrapper">
                    <img class="box__img" 
                      src="../../../../assets/images/component.png" alt="Skeleton of a website page to showcase the usage of general components on a layout. The UI is in dark mode with purple accents">
                  </div>
                  <p class="box__title center">Components</p>
                  <p class="box__description">Over 200+ Components</p>
                </div>
                <!-- 2nd -->
                <div class="box">
                  <div class="img-wrapper">
                    <img class="box__img icon" 
                    src="../../../../assets/images/atom.png" alt="Purple shaded atom icon with a dark gradient to the bottom">
                  </div>
                  <p class="box__title center">Atomic Design</p>
                  <p class="box__description">with a personal spin & some BEM</p>
                </div>
                <!-- 3rd -->
                <div class="box">
                  <div class="img-wrapper">
                    <img class="box__img icon" 
                    src="../../../../assets/images/heart.png" alt="Purple shaded atom icon with a dark gradient to the bottom">
                  </div>
                  <p class="box__title center">Icons & Illustrations</p>
                  <p class="box__description">Includes its own library of Icons & Illustrations</p>
                </div>
                <!-- 4th -->
                <div class="box">
                  <div class="img-wrapper">
                    <img class="box__img" 
                    src="../../../../assets/images/npm.png" alt="VS Code screenshot of a code in a dracula theme">
                  </div>
                  <p class="box__title center">NPM Library</p>
                  <p class="box__description">Developed in Angular, Material & custom SCSS</p>
                </div>
              </div>
            </div>
            <div class="actions mrg-t-sm">
              <button class="primary">
                <!-- <div class="left">
                  <h6>learn more</h6>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8L18 12M18 12L14 16M18 12H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                </div> -->
                <div class="right">
                  <!-- <h6>let's go!</h6> -->
                  <h6>full case study - coming soon</h6>
                </div>
              </button>
            </div>
          </div>
        </div>
  </div>
</div>