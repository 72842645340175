// Angular
import { NgModule } from '@angular/core';
// EMV Customer Portal Shared (current module)
import { ToLogoPipe } from './to-logo.pipe';

@NgModule({
  imports: [],
  declarations: [ToLogoPipe],
  exports: [ToLogoPipe],
  providers: [],
})
export class SharedPipesModule {}
