// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Atomics libs
import { Logo } from '@atomics/ui';
// Portfolio MLV Core
import { SharedLogoTranslations } from '@portfolio-mlv/core/internationalization';

@Pipe({ name: 'toLogo' })
export class ToLogoPipe implements PipeTransform {
  constructor() {}

  transform(
    logoTranslations: SharedLogoTranslations,
    logo: Logo,
    href: string
  ): Logo {
    const { alt } = logoTranslations;

    if (logo) {
      logo.href = href;

      if (logo.img) {
        logo.img.alt = alt;
      }
    }

    return logo;
  }
}
