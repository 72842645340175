import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Input, Output, NgModule, Pipe, LOCALE_ID, Inject } from '@angular/core';
import { interval } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { formatNumber } from '@angular/common';
import * as i1 from '@atomics/adapters';
import { Settings, Interval, DateTime } from 'luxon';

// Angular
class ClockService {
  constructor() {
    this.clock = interval().pipe(map(tick => new Date()), share());
  }
  getClock() {
    return this.clock;
  }
  static {
    this.ɵfac = function ClockService_Factory(t) {
      return new (t || ClockService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ClockService,
      factory: ClockService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClockService, [{
    type: Injectable
  }], () => [], null);
})();
class FormBuilderService {
  applyFormOptions(formGroup, options) {
    if (!options) {
      return;
    }
    const {
      disableAll,
      disableFields,
      enableFields
    } = options;
    if (disableAll) {
      // Disable all the controls
      formGroup.disable();
    } else if (disableFields && disableFields.length >= 1) {
      // Disable specific fields in the form
      Object.keys(formGroup.controls).forEach(key => {
        if (disableFields.includes(key)) {
          formGroup.controls[key].disable();
        }
      });
    } else if (enableFields && enableFields.length >= 1) {
      // Disable all and enable specific fields
      formGroup.disable();
      Object.keys(formGroup.controls).forEach(key => {
        if (enableFields.includes(key)) {
          formGroup.controls[key].enable();
        }
      });
    }
  }
}

// Angular
class FormParentComponent {
  constructor(builder) {
    this.builder = builder;
    this.loading = false;
    this.formSubmit = new EventEmitter();
  }
  initForm(formValues) {
    this.form = this.builder.build(formValues, this.formOptions);
  }
  static {
    this.ɵfac = function FormParentComponent_Factory(t) {
      return new (t || FormParentComponent)(i0.ɵɵdirectiveInject(FormBuilderService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FormParentComponent,
      selectors: [["form-parent"]],
      inputs: {
        loading: "loading",
        errors: "errors",
        formOptions: "formOptions"
      },
      outputs: {
        formSubmit: "formSubmit"
      },
      decls: 0,
      vars: 0,
      template: function FormParentComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormParentComponent, [{
    type: Component,
    args: [{
      selector: 'form-parent',
      template: ''
    }]
  }], () => [{
    type: FormBuilderService
  }], {
    loading: [{
      type: Input
    }],
    errors: [{
      type: Input
    }],
    formOptions: [{
      type: Input
    }],
    formSubmit: [{
      type: Output
    }]
  });
})();
const regexConsts = {
  FLOAT_IT_NUMBER: /[^0-9,]/g,
  PHONE_NUMBER: /[^0-9+]/g,
  ALPHA_NUMERIC: /[^0-9a-zA-Z]/g
};
var StatusSeverityLevels;
(function (StatusSeverityLevels) {
  StatusSeverityLevels["ERROR"] = "error";
  StatusSeverityLevels["SUCCESS"] = "success";
  StatusSeverityLevels["WARNING"] = "warning";
})(StatusSeverityLevels || (StatusSeverityLevels = {}));
var StatusEnum;
(function (StatusEnum) {
  StatusEnum["ABANDONED"] = "abandoned";
  StatusEnum["ACTIVE"] = "active";
  StatusEnum["APPROVED"] = "approved";
  StatusEnum["BLOCKED"] = "blocked";
  StatusEnum["CANCELLED"] = "cancelled";
  StatusEnum["CONFIRMED"] = "confirmed";
  StatusEnum["DECLINED"] = "declined";
  StatusEnum["DELETED"] = "deleted";
  StatusEnum["PENDING"] = "pending";
  StatusEnum["PARTIAL"] = "partial";
  StatusEnum["TEMPORARY_LOCKED"] = "temporaryLocked";
  StatusEnum["UNKNOWN"] = "unknown";
})(StatusEnum || (StatusEnum = {}));

// Angular
class InjectorContainerModule {
  constructor(injector) {
    InjectorContainerModule.injector = injector;
  }
  static {
    this.ɵfac = function InjectorContainerModule_Factory(t) {
      return new (t || InjectorContainerModule)(i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: InjectorContainerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InjectorContainerModule, [{
    type: NgModule
  }], () => [{
    type: i0.Injector
  }], null);
})();
class ApiErrorPipe {
  constructor() {}
  transform(apiErrors, controlName) {
    if (!apiErrors || !controlName) {
      return null;
    }
    return apiErrors[controlName];
  }
  static {
    this.ɵfac = function ApiErrorPipe_Factory(t) {
      return new (t || ApiErrorPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "apiError",
      type: ApiErrorPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiErrorPipe, [{
    type: Pipe,
    args: [{
      name: 'apiError'
    }]
  }], () => [], null);
})();

// Angular
class CalculatePercentagePipe {
  constructor(locale) {
    this.locale = locale;
  }
  transform(value, totalValue, percentageFormat, symbol) {
    const percentage = value / totalValue * 100;
    let percentageFormatted = formatNumber(percentage, this.locale, percentageFormat);
    if (symbol) {
      percentageFormatted = `${percentageFormatted}${symbol}`;
    }
    return percentageFormatted;
  }
  static {
    this.ɵfac = function CalculatePercentagePipe_Factory(t) {
      return new (t || CalculatePercentagePipe)(i0.ɵɵdirectiveInject(LOCALE_ID, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "calculatePercentage",
      type: CalculatePercentagePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalculatePercentagePipe, [{
    type: Pipe,
    args: [{
      name: 'calculatePercentage'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LOCALE_ID]
    }]
  }], null);
})();

// Angular
class CallStateToLoadingPipe {
  constructor() {}
  transform(callState) {
    switch (callState) {
      case "INIT" /* LoadingState.INIT */:
        return false;
      case "LOADED" /* LoadingState.LOADED */:
        return false;
      case "LOADING" /* LoadingState.LOADING */:
        return true;
      default:
        return false;
    }
  }
  static {
    this.ɵfac = function CallStateToLoadingPipe_Factory(t) {
      return new (t || CallStateToLoadingPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "callStateToLoading",
      type: CallStateToLoadingPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CallStateToLoadingPipe, [{
    type: Pipe,
    args: [{
      name: 'callStateToLoading'
    }]
  }], () => [], null);
})();

// Angular
class CastControlToFormGroupPipe {
  constructor() {}
  transform(formControl) {
    return formControl;
  }
  static {
    this.ɵfac = function CastControlToFormGroupPipe_Factory(t) {
      return new (t || CastControlToFormGroupPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "castControlToFormGroup",
      type: CastControlToFormGroupPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CastControlToFormGroupPipe, [{
    type: Pipe,
    args: [{
      name: 'castControlToFormGroup'
    }]
  }], () => [], null);
})();
class FindElementInArrayPipe {
  constructor() {}
  transform(arr, element) {
    if (!arr || !arr.length || !element) {
      return false;
    }
    const elementFound = arr.find(el => el === element);
    return !elementFound ? false : true;
  }
  static {
    this.ɵfac = function FindElementInArrayPipe_Factory(t) {
      return new (t || FindElementInArrayPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "findElementInArray",
      type: FindElementInArrayPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FindElementInArrayPipe, [{
    type: Pipe,
    args: [{
      name: 'findElementInArray'
    }]
  }], () => [], null);
})();

// Store

// Utility function to extract the errors from the state
const getErrors = callState => {
  if (callState && callState.errors !== undefined) {
    return callState.errors;
  }
  return null;
};
// Utility function to extract status code and error messages from the error action
const getErrorsData = action => {
  if (action && action.errors !== undefined) {
    const {
      status,
      error
    } = action.errors;
    return {
      status,
      messages: error.messages
    };
  }
  return null;
};

// Angular
class IsErrorStatePipe {
  constructor() {}
  transform(callState) {
    if (!callState) {
      return false;
    }
    const callStateErrors = getErrors(callState);
    if (!callStateErrors) {
      return false;
    }
    return true;
  }
  static {
    this.ɵfac = function IsErrorStatePipe_Factory(t) {
      return new (t || IsErrorStatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "isErrorState",
      type: IsErrorStatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IsErrorStatePipe, [{
    type: Pipe,
    args: [{
      name: 'isErrorState'
    }]
  }], () => [], null);
})();

// Angular
const mailToConsts = {
  BODY: 'body',
  MAIL_TO: 'mailTo',
  SUBJECT: 'subject'
};
class MailToPipe {
  constructor() {}
  transform(mailToConfigs) {
    if (!mailToConfigs || !mailToConfigs.mailTo) {
      return '';
    }
    const {
      mailTo,
      subject,
      body
    } = mailToConfigs;
    let result = `${mailToConsts.MAIL_TO}:${mailTo}`;
    if (subject) {
      result = `${result}?${mailToConsts.SUBJECT}=${subject}`;
    }
    if (body) {
      result = `${result}&${mailToConsts.BODY}=${body}`;
    }
    return result;
  }
  static {
    this.ɵfac = function MailToPipe_Factory(t) {
      return new (t || MailToPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "mailTo",
      type: MailToPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MailToPipe, [{
    type: Pipe,
    args: [{
      name: 'mailTo'
    }]
  }], () => [], null);
})();
class TranslateObjectPipe {
  constructor(translocoAdapter) {
    this.translocoAdapter = translocoAdapter;
  }
  transform(key, params) {
    return this.translocoAdapter.translateObject(key, params, this.translocoAdapter.getActiveLang());
  }
  static {
    this.ɵfac = function TranslateObjectPipe_Factory(t) {
      return new (t || TranslateObjectPipe)(i0.ɵɵdirectiveInject(i1.TranslocoAdapterService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translateObj",
      type: TranslateObjectPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateObjectPipe, [{
    type: Pipe,
    args: [{
      name: 'translateObj'
    }]
  }], () => [{
    type: i1.TranslocoAdapterService
  }], null);
})();

// Angular
class PipesModule {
  static {
    this.ɵfac = function PipesModule_Factory(t) {
      return new (t || PipesModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PipesModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [TranslateObjectPipe]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PipesModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [ApiErrorPipe, CalculatePercentagePipe, CallStateToLoadingPipe, CastControlToFormGroupPipe, FindElementInArrayPipe, IsErrorStatePipe, MailToPipe, TranslateObjectPipe],
      exports: [ApiErrorPipe, CalculatePercentagePipe, CallStateToLoadingPipe, CastControlToFormGroupPipe, FindElementInArrayPipe, IsErrorStatePipe, MailToPipe, TranslateObjectPipe],
      providers: [TranslateObjectPipe]
    }]
  }], null, null);
})();

// Pipes

function intersection(arrA, arrB) {
  return arrA.filter(value => arrB.includes(value));
}
function emptyIntersection(arrA, arrB) {
  return intersection(arrA, arrB).length === 0;
}
function capitalizeFirstLetterLowercaseRest(text) {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
function slugify(text) {
  if (!text) {
    return '';
  }
  return String(text).normalize('NFKD') // split accented characters into their base characters and diacritical marks
  .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
  .trim() // trim leading or trailing whitespace
  .toLowerCase() // convert to lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
  .replace(/\s+/g, '-') // replace spaces with hyphens
  .replace(/-+/g, '-'); // remove consecutive hyphens
}

// Luxon
function setGlobalLibsLocale(defaultLocale) {
  // Setting Luxon library global local
  Settings.defaultLocale = defaultLocale;
}
function setGlobalLibsZone(defaultZone) {
  // Setting Luxon library global local
  Settings.defaultZone = defaultZone;
}
function compareDates(firstDateTime, secondDateTime) {
  return fromISO(firstDateTime).toMillis() - fromISO(secondDateTime).toMillis();
}
function compareDateTimes(firstDateTime, secondDateTime) {
  return firstDateTime.toMillis() - secondDateTime.toMillis();
}
function currentMonth() {
  const currentDate = now();
  return currentDate.monthLong;
}
function currentYear() {
  const currentDate = now();
  return currentDate.year;
}
function dateTimesContains(startDateTime, endDateTime, dateTimeToCheck) {
  return Interval.fromDateTimes(startDateTime, endDateTime).contains(dateTimeToCheck);
}
function intervalContains(interval, dateTimeToCheck) {
  return interval.contains(dateTimeToCheck);
}
function fromFormatDateMed(dateTimeStr, format) {
  return DateTime.fromFormat(dateTimeStr, format, DateTime.DATE_MED);
}
function fromISO(dateTimeStr) {
  return DateTime.fromISO(dateTimeStr);
}
function fromJSDate(dateTimeStr) {
  return DateTime.fromJSDate(new Date(dateTimeStr));
}
function fromSQLDate(dateTimeStr) {
  return DateTime.fromSQL(dateTimeStr);
}
function fromIntervalISO(intervalStr) {
  return Interval.fromISO(intervalStr);
}
function format24HoursAndMinutes(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
  return humanReadableDate;
}
function format24HoursAndMinutesAndSeconds(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  return humanReadableDate;
}
function formatCustomDateTime(dateTimeStr, customFormat, zone) {
  const luxonDateTime = fromISO(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toFormat(customFormat);
  return humanReadableDate;
}
function formatDateFull(dateTimeStr) {
  const luxonDateTime = fromISO(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATE_FULL);
  return humanReadableDate;
}
function formatDateMed(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATE_MED);
  return humanReadableDate;
}
function formatDateShortFromSQLDate(dateTimeStr) {
  const luxonDateTime = fromSQLDate(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATE_SHORT);
  return humanReadableDate;
}
function formatDateMedFromSQLDate(dateTimeStr) {
  const luxonDateTime = fromSQLDate(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATE_MED);
  return humanReadableDate;
}
function formatDateShort(dateTimeStr) {
  const luxonDateTime = fromISO(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATE_SHORT);
  return humanReadableDate;
}
function formatDateTimeShort(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATETIME_SHORT);
  return humanReadableDate;
}
function formatDateTimeMed(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATETIME_MED);
  return humanReadableDate;
}
function formatDateTimeMedWithSeconds(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  return humanReadableDate;
}
function formatDateTimeMedFromSQLDate(dateTimeStr) {
  const luxonDateTime = fromSQLDate(dateTimeStr);
  // Human Readable is the term used by Luxon
  const humanReadableDate = luxonDateTime.toLocaleString(DateTime.DATETIME_MED);
  return humanReadableDate;
}
function formatDateTimeISO(dateTimeStr) {
  const luxonDateTime = fromISO(dateTimeStr);
  const result = luxonDateTime.toISO();
  return !result ? '' : result;
}
function toTimeStamp(dateTimeStr, zone) {
  let luxonDateTime = fromISO(dateTimeStr);
  if (zone) {
    luxonDateTime = luxonDateTime.setZone(zone);
  }
  return luxonDateTime.toMillis();
}
function getMonthDecades(dateTimeStr) {
  const date = fromISO(dateTimeStr);
  const decades = calculateMonthDecades(date);
  return decades;
}
function getCurrentMonthDecades() {
  const today = now();
  const decades = calculateMonthDecades(today);
  return decades;
}
function calculateMonthDecades(date) {
  // First decade
  const firstDecadeStart = date.startOf('month');
  const firstDecadeEnd = firstDecadeStart.plus({
    days: 9
  });
  const firstDecade = Interval.fromDateTimes(firstDecadeStart, firstDecadeEnd);
  // Second decade
  const secondDecadeStart = firstDecadeEnd.plus({
    days: 1
  });
  const secondDecadeEnd = secondDecadeStart.plus({
    days: 9
  });
  const secondDecade = Interval.fromDateTimes(secondDecadeStart, secondDecadeEnd);
  // Third decade
  const thirdDecadeStart = secondDecadeEnd.plus({
    days: 1
  });
  const thirdDecadeEnd = date.endOf('month');
  const thirdDecade = Interval.fromDateTimes(thirdDecadeStart, thirdDecadeEnd);
  return {
    firstDecade,
    firstDecadeStart,
    firstDecadeEnd,
    secondDecade,
    secondDecadeStart,
    secondDecadeEnd,
    thirdDecade,
    thirdDecadeStart,
    thirdDecadeEnd
  };
}
function getPreviousMonths(monthsFilter) {
  let months = [];
  const today = now();
  const monthsEnd = today.endOf('month');
  let tmpMonth = today.minus({
    months: monthsFilter
  }).startOf('month');
  while (tmpMonth <= monthsEnd) {
    const monthLabel = tmpMonth.toFormat('MMMM');
    const yearLabel = tmpMonth.year;
    const label = capitalizeFirstLetterLowercaseRest(`${monthLabel} ${yearLabel}`);
    const value = tmpMonth.toISO();
    months = [...months, {
      label,
      isoValue: !value ? '' : value
    }];
    tmpMonth = tmpMonth.plus({
      months: 1
    });
  }
  return months;
}
function now() {
  return DateTime.now();
}
function fromSecToMs(value) {
  return value * 1000;
}
function bufferToBlob(int8Array) {
  return new Blob([int8Array]);
}
function bufferToBase64Url(int8Array) {
  let base64String = '';
  const len = int8Array.byteLength;
  for (let i = 0; i < len; i++) {
    base64String += String.fromCharCode(int8Array[i]);
  }
  base64String = window.btoa(base64String);
  let prefix = 'data';
  switch (base64String.charAt(0)) {
    case '/':
      prefix = `${prefix}:image/jpg`;
      break;
    case 'i':
      prefix = `${prefix}:image/png`;
      break;
    case 'J':
      prefix = `${prefix}:application/pdf`;
      break;
    default:
      prefix = `${prefix}:image/png`;
      break;
  }
  return `${prefix};base64,${base64String}`;
}
function getFileExtension(fileName) {
  const regex = /(?:\.([^.]+))?$/;
  const result = regex.exec(fileName);
  return !result ? '' : !result[1] ? '' : result[1];
}
function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
async function generatePassword(length) {
  if (length < 1) {
    throw new Error('Length must be greater than 0');
  }
  const buffer = new Uint8Array(length);
  // Get the browser's crypto object for generating random numbers.
  const crypto = window.crypto || window.msCrypto; // For compatibility with IE11.
  const array = await crypto.getRandomValues(buffer);
  let password = '';
  // Define the characters that can be used in the password.
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // Iterate over the array of random values and add characters to the password.
  for (let i = 0; i < length; i++) {
    // Use the modulus operator to get a random index in the characters string
    // and add the corresponding character to the password.
    password += characters.charAt(array[i] % characters.length);
  }
  // Return the generated password.
  return password;
}

/**
 * Generated bundle index. Do not edit.
 */

export { ApiErrorPipe, CalculatePercentagePipe, CallStateToLoadingPipe, CastControlToFormGroupPipe, ClockService, FindElementInArrayPipe, FormBuilderService, FormParentComponent, InjectorContainerModule, IsErrorStatePipe, MailToPipe, PipesModule, StatusEnum, StatusSeverityLevels, TranslateObjectPipe, bufferToBase64Url, bufferToBlob, calculateMonthDecades, capitalizeFirstLetterLowercaseRest, compareDateTimes, compareDates, currentMonth, currentYear, dateTimesContains, emptyIntersection, format24HoursAndMinutes, format24HoursAndMinutesAndSeconds, formatCustomDateTime, formatDateFull, formatDateMed, formatDateMedFromSQLDate, formatDateShort, formatDateShortFromSQLDate, formatDateTimeISO, formatDateTimeMed, formatDateTimeMedFromSQLDate, formatDateTimeMedWithSeconds, formatDateTimeShort, fromFormatDateMed, fromISO, fromIntervalISO, fromJSDate, fromSQLDate, fromSecToMs, generatePassword, getCurrentMonthDecades, getErrors, getErrorsData, getFileExtension, getMonthDecades, getPreviousMonths, intersection, intervalContains, isEmpty, now, regexConsts, setGlobalLibsLocale, setGlobalLibsZone, slugify, toTimeStamp };
