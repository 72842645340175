// Angular
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
// RxJS
import { lastValueFrom, forkJoin } from 'rxjs';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
import { setGlobalLibsLocale } from '@atomics/common';
// Portfolio MLV Core
import {
  langConsts,
  scopesNames,
} from '@portfolio-mlv/core/internationalization';

@Injectable()
export class LocalizationService {
  constructor(private translocoAdapterService: TranslocoAdapterService) {}

  setLocale(locale: string) {
    // App locale
    if (locale === langConsts.ITALIAN.id) {
      registerLocaleData(localeIt);
    } else if (locale === langConsts.ENGLISH.id) {
      registerLocaleData(localeEn);
    } else {
      // Default
      registerLocaleData(localeIt);
    }

    // Set global locale for libraries
    setGlobalLibsLocale(locale);

    // Set transloco i18
    // Load all the scopes, because we can not understand where we are
    let translations$ = [];
    translations$.push(this.translocoAdapterService.load(locale));
    translations$.push(
      this.translocoAdapterService.load(`${scopesNames.LANDING}/${locale}`)
    );
    translations$.push(
      this.translocoAdapterService.load(`${scopesNames.HOME_ROOT}/${locale}`)
    );
    translations$.push(
      this.translocoAdapterService.load(`${scopesNames.HOME_MAIN}/${locale}`)
    );
    translations$.push(
      this.translocoAdapterService.load(
        `${scopesNames.HOME_TRANSACTIONS}/${locale}`
      )
    );

    forkJoin(...translations$).subscribe((value) => {
      this.translocoAdapterService.setActiveLang(locale);
    });

    // this.translocoAdapterService.load(translationsPath).subscribe(value => {
    //   console.log(value);
    //   this.translocoAdapterService.setActiveLang(locale);
    // });
  }
}
