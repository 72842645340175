// Angular
import { Component, OnInit, OnDestroy } from '@angular/core';
// Atomics Libs
import {
  TranslocoAdapterService,
  TranslocoEventEnum,
  RouterAdapterService,
} from '@atomics/adapters';
import { svgIconsConsts } from '@atomics/icons';
// NgRx
import { Store, select } from '@ngrx/store';
// RxJS
import { filter, Observable, Subscription } from 'rxjs';
// Env
import { environment } from '@portfolio-mlv/environments';
// Portfolio MLV Core
import {
  LocalizationService,
  scopesNamesObj,
  LandingTranslations,
} from '@portfolio-mlv/core/internationalization';
// EMV Customer Portal Landing (current module)
import { landingRoutes } from '../../constants';
import { LandingState, LandingActions, selectLandingPageVM } from '../../store';
import { LandingPageVM } from '../../types';

@Component({
  selector: 'app-landing-page',
  templateUrl: 'landing-page.component.html',
  styleUrls: ['landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  translations$: Observable<LandingTranslations> =
    this.translocoAdapterService.selectTranslateObject(scopesNamesObj.LANDING);
  landingPageVM$: Observable<LandingPageVM> = this.store.pipe(
    select(selectLandingPageVM)
  );

  logoHref: string = landingRoutes.LANDING;

  appVersion = environment.version;

  svgIconsConsts = svgIconsConsts;

  subscriptions = new Subscription();

  constructor(
    private localizationService: LocalizationService,
    private store: Store<LandingState>,
    private translocoAdapterService: TranslocoAdapterService,
    private routerAdapterService: RouterAdapterService
  ) {}

  ngOnInit(): void {
    this.initContainer();
    this.initLangChangesSubscription();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  onChangeLocalization(lang: string) {
    this.localizationService.setLocale(lang);
  }

  initContainer() {
    this.store.dispatch(LandingActions.initContainer());
  }

  initLangChangesSubscription() {
    this.subscriptions.add(
      this.translocoAdapterService
        .events()
        .pipe(filter((e) => e.type === TranslocoEventEnum.LANG_CHANGED))
        .subscribe((value) => {
          this.routerAdapterService.navigateByUrl(landingRoutes.LANDING);
        })
    );
  }
}
