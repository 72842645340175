// Angular
import { Injectable } from '@angular/core';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
import { svgIconsConsts } from '@atomics/icons';
import { ToastData } from '@atomics/ui';

@Injectable()
export class ToastAdapterService {
  constructor(private translocoAdapterService: TranslocoAdapterService) {}

  toToastDataError(): ToastData {
    const toastText = this.translocoAdapterService.translate('errors.default');
    const toastData: ToastData = {
      svgIcon: svgIconsConsts.STATUS_ERROR,
      text: toastText,
      types: ['error', 'no-mrg', 'shadow-neutral'],
    };
    return toastData;
  }
}
