import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  apiUrl: 'http://localhost:3000',
  logger: {
    level: NgxLoggerLevel.DEBUG,
    serverLevel: NgxLoggerLevel.OFF,
    serverUrl: undefined,
  },
  version: 'v1.0.0',
};
