// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Transloco
import {
  provideTransloco,
  TRANSLOCO_CONFIG,
  TranslocoModule,
  translocoConfig,
} from '@ngneat/transloco';
// Atomics libs
import { LoggerAdapterService } from '@atomics/adapters';
// Env
import { environment } from '@portfolio-mlv/environments';
// Portfolio MLV Core Internationalization (current module)
import { availableLangs } from './constants';
import { TranslocoHttpLoader } from './transloco.loader';

const MODULE_NAME = 'TranslocoRootModule';

const defaultLang: string = 'it';
const fallbackLang: string = 'it';

export type AvailableLang = string | { id: string; label: string } | undefined;

@NgModule({
  imports: [CommonModule, TranslocoModule],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs,
        defaultLang,
        reRenderOnLangChange: true, // set to true when dynamic language change is in place
        fallbackLang,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {
  constructor(private logger: LoggerAdapterService) {
    this.logger.debug(
      MODULE_NAME,
      'constructor',
      `Available languages are: ${
        availableLangs ? JSON.stringify(availableLangs) : ''
      }`
    );
    this.logger.debug(
      MODULE_NAME,
      'constructor',
      `Default language is: [${defaultLang}]`
    );
    this.logger.debug(
      MODULE_NAME,
      'constructor',
      `Fall back language is: [${fallbackLang}]`
    );
  }
}
