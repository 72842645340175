<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      landingPageVM: landingPageVM$ | async,
      translations: translations$ | async
    } as vm"
  >
  <div class="main">
    <div class="background">      
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>

    <div class="content">
      <div class="section">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="assets/images/card.png" alt="Avatar" >
            </div>
            <div class="flip-card-back">
              <div class="inner">
                <div class="hero">
                  <h1 class="text--gradient">Product Designer</h1>
                  <p class="description">
                    Passionate about building scalable, impactful products through cross-functional collaboration and 
                    fostering a culture of design excellence.
                  </p>
                  <p class="purple">Skills</p>
                  <ul>
                    <li>Product Strategy & Goal Setting</li>
                    <li>Team Leadership</li>
                    <li>End-to-end Product Design</li>
                    <li>Design Systems</li>
                    <li>Data-driven design decisions</li>
                  </ul>
  
                  <div class="actions-wrapper">
                    <div class="row">
                      <a class="btn neon-pink" 
                      href="assets/documents/micaela-villani-sr-product-designer.pdf" 
                      download>
                        <img src="assets/icons/cloud-download.svg">
                        Resumé
                      </a>
                      <a class="btn neon-purple"
                        href="https://www.figma.com/deck/rn8yHVHrRAvHYOEGPBa16N/Micaela-Villani---Sr.-Product-Designer?node-id=47-1910&node-type=slide&viewport=81%2C-132%2C0.72&t=c8K3EsQ7RwLoMA9q-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1" target="_blank">
                        <img src="assets/icons/portfolio.svg">
                        Portfolio
                      </a>
                    </div>
                    <div class="row">
                      <a class="btn neon-blue"
                        href="https://www.linkedin.com/in/micaelavillani/" 
                        target="_blank">
                        <img src="assets/icons/linkedin.svg">
                        Linkedin
                      </a>
                      <a class="btn neon-green" 
                        href="https://bento.me/micaelavillani"
                        target="_blank">
                        <img src="assets/icons/mailbox.svg">
                        Contacts
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <img src="assets/icons/sparkle.svg" alt="Two vertical Sparkling stars icons in purple">
          <p>This simple page has been freely inspired by my latest favourite tv series: 
            <strong>Agatha All Along</strong> on Disney+</p>
        </div>
      </div>
    </div>
  </div>
  </ng-container>
</ng-container>
