const svgIconExt = '.svg';
const svgIconNamePrefix = 'icon--';
const svgIconsPath = 'assets/icons';
const svgIconsConsts = {
    ADD: `${svgIconsPath}/${svgIconNamePrefix}add${svgIconExt}`,
    ARROW_DOWN: `${svgIconsPath}/${svgIconNamePrefix}arrow-down${svgIconExt}`,
    ARROW_LEFT: `${svgIconsPath}/${svgIconNamePrefix}arrow-left${svgIconExt}`,
    ARROW_RIGHT: `${svgIconsPath}/${svgIconNamePrefix}arrow-right${svgIconExt}`,
    BADGE_SECURITY: `${svgIconsPath}/${svgIconNamePrefix}badge--security${svgIconExt}`,
    BADGE_SUCCESS: `${svgIconsPath}/${svgIconNamePrefix}badge--success${svgIconExt}`,
    BELL: `${svgIconsPath}/${svgIconNamePrefix}bell${svgIconExt}`,
    BUS: `${svgIconsPath}/${svgIconNamePrefix}bus${svgIconExt}`,
    CIRCLE_CHECK: `${svgIconsPath}/${svgIconNamePrefix}circle-check${svgIconExt}`,
    CLOCK: `${svgIconsPath}/${svgIconNamePrefix}clock${svgIconExt}`,
    CLOSE: `${svgIconsPath}/${svgIconNamePrefix}close${svgIconExt}`,
    COINS: `${svgIconsPath}/${svgIconNamePrefix}coins${svgIconExt}`,
    CONCESSION: `${svgIconsPath}/${svgIconNamePrefix}concession${svgIconExt}`,
    COPY: `${svgIconsPath}/${svgIconNamePrefix}copy${svgIconExt}`,
    CREDIT_CARD: `${svgIconsPath}/${svgIconNamePrefix}credit-card${svgIconExt}`,
    CROSS: `${svgIconsPath}/${svgIconNamePrefix}cross${svgIconExt}`,
    DASHBOARD: `${svgIconsPath}/${svgIconNamePrefix}dashboard${svgIconExt}`,
    DELETE: `${svgIconsPath}/${svgIconNamePrefix}delete${svgIconExt}`,
    DOUBLE_CHECK: `${svgIconsPath}/${svgIconNamePrefix}double-check${svgIconExt}`,
    DOCUMENT_DOWNLOAD: `${svgIconsPath}/${svgIconNamePrefix}document-download${svgIconExt}`,
    DOCUMENT_SEARCH: `${svgIconsPath}/${svgIconNamePrefix}document-search${svgIconExt}`,
    DOCUMENT: `${svgIconsPath}/${svgIconNamePrefix}document${svgIconExt}`,
    EDIT: `${svgIconsPath}/${svgIconNamePrefix}edit${svgIconExt}`,
    ERROR: `${svgIconsPath}/${svgIconNamePrefix}error${svgIconExt}`,
    EYE_CROSSED: `${svgIconsPath}/${svgIconNamePrefix}eye-crossed${svgIconExt}`,
    EYE: `${svgIconsPath}/${svgIconNamePrefix}eye${svgIconExt}`,
    FILE_CHART_LINE: `${svgIconsPath}/${svgIconNamePrefix}file-chart-line${svgIconExt}`,
    FILE_CHECK: `${svgIconsPath}/${svgIconNamePrefix}file-check${svgIconExt}`,
    FILTERS_SLIDERS: `${svgIconsPath}/${svgIconNamePrefix}filters-sliders${svgIconExt}`,
    FLAG_GB: `${svgIconsPath}/${svgIconNamePrefix}flag--gb${svgIconExt}`,
    FLAG_ITALY: `${svgIconsPath}/${svgIconNamePrefix}flag--italy${svgIconExt}`,
    FLAG_SPAIN: `${svgIconsPath}/${svgIconNamePrefix}flag--espana${svgIconExt}`,
    FLAG: `${svgIconsPath}/${svgIconNamePrefix}flag${svgIconExt}`,
    GRAPH: `${svgIconsPath}/${svgIconNamePrefix}graph${svgIconExt}`,
    HISTORY: `${svgIconsPath}/${svgIconNamePrefix}history${svgIconExt}`,
    HOME: `${svgIconsPath}/${svgIconNamePrefix}home${svgIconExt}`,
    INSPECT: `${svgIconsPath}/${svgIconNamePrefix}inspect${svgIconExt}`,
    LIKE: `${svgIconsPath}/${svgIconNamePrefix}like${svgIconExt}`,
    LIST: `${svgIconsPath}/${svgIconNamePrefix}list${svgIconExt}`,
    LOGOUT: `${svgIconsPath}/${svgIconNamePrefix}logout${svgIconExt}`,
    MOBILE: `${svgIconsPath}/${svgIconNamePrefix}mobile${svgIconExt}`,
    MOON: `${svgIconsPath}/${svgIconNamePrefix}moon${svgIconExt}`,
    PASSENGER: `${svgIconsPath}/${svgIconNamePrefix}passenger${svgIconExt}`,
    PAYMENT_AMEX: `${svgIconsPath}/${svgIconNamePrefix}payment--amex${svgIconExt}`,
    PAYMENT_MASTERCARD: `${svgIconsPath}/${svgIconNamePrefix}payment--mastercard${svgIconExt}`,
    PAYMENT_VISA: `${svgIconsPath}/${svgIconNamePrefix}payment--visa${svgIconExt}`,
    PAYMENT_VPAY: `${svgIconsPath}/${svgIconNamePrefix}payment--vpay${svgIconExt}`,
    PIN: `${svgIconsPath}/${svgIconNamePrefix}pin${svgIconExt}`,
    PORTRAIT: `${svgIconsPath}/${svgIconNamePrefix}portrait${svgIconExt}`,
    RECEIPT_DOWNLOAD: `${svgIconsPath}/${svgIconNamePrefix}receipt-download${svgIconExt}`,
    SEARCH: `${svgIconsPath}/${svgIconNamePrefix}search${svgIconExt}`,
    SECURITY_ERROR: `${svgIconsPath}/${svgIconNamePrefix}security--error${svgIconExt}`,
    SECURITY: `${svgIconsPath}/${svgIconNamePrefix}security${svgIconExt}`,
    SEND_EMAIL: `${svgIconsPath}/${svgIconNamePrefix}send-email${svgIconExt}`,
    SMART_WATCH: `${svgIconsPath}/${svgIconNamePrefix}smart-watch${svgIconExt}`,
    STATUS_ERROR: `${svgIconsPath}/${svgIconNamePrefix}status--error${svgIconExt}`,
    STATUS_INFO: `${svgIconsPath}/${svgIconNamePrefix}status--info${svgIconExt}`,
    STATUS_SUCCESS: `${svgIconsPath}/${svgIconNamePrefix}status--success${svgIconExt}`,
    STATUS_WARNING: `${svgIconsPath}/${svgIconNamePrefix}status--warning${svgIconExt}`,
    STORE: `${svgIconsPath}/${svgIconNamePrefix}store${svgIconExt}`,
    SUN: `${svgIconsPath}/${svgIconNamePrefix}sun${svgIconExt}`,
    SUPPORT: `${svgIconsPath}/${svgIconNamePrefix}support${svgIconExt}`,
    TABLET: `${svgIconsPath}/${svgIconNamePrefix}tablet${svgIconExt}`,
    TITLES_ACTIVE: `${svgIconsPath}/${svgIconNamePrefix}titles-active${svgIconExt}`,
    TITLES_EXPIRED: `${svgIconsPath}/${svgIconNamePrefix}titles-expired${svgIconExt}`,
    TRAM: `${svgIconsPath}/${svgIconNamePrefix}tram${svgIconExt}`,
    USER_CIRCLE: `${svgIconsPath}/${svgIconNamePrefix}user-circle${svgIconExt}`,
    USER_SHAPE: `${svgIconsPath}/${svgIconNamePrefix}user-shape${svgIconExt}`,
    USER: `${svgIconsPath}/${svgIconNamePrefix}user${svgIconExt}`,
    VERIFIED: `${svgIconsPath}/${svgIconNamePrefix}verified${svgIconExt}`,
    VIEW: `${svgIconsPath}/${svgIconNamePrefix}view${svgIconExt}`,
    WARNING: `${svgIconsPath}/${svgIconNamePrefix}warning${svgIconExt}`,
    WEB_USER: `${svgIconsPath}/${svgIconNamePrefix}user${svgIconExt}`,
    WIRELESS: `${svgIconsPath}/${svgIconNamePrefix}wireless${svgIconExt}`,
    WORLD: `${svgIconsPath}/${svgIconNamePrefix}world${svgIconExt}`
};

/**
 * Generated bundle index. Do not edit.
 */

export { svgIconsConsts };

