<ng-container
  *ngIf="{
    translations: translations$ | async
  } as footerVM"
>
  <atomics-footer>
    <!-- LEFT SECTION -->
    <div left class="left">test</div>
    <!-- CENTRAL SECTION -->
    <div center>
      <atomics-text
        [labels]="footerVM.translations?.copyright + ' ' + currentYear | toTextLabels"
        [types]="['info', 'light', 'xs', 'no-mrg']"
      ></atomics-text>
    </div>
    <!-- RIGHT SECTION -->
    <div right>
      <!-- LANGUAGES BUTTON -->
      <div class="languages-wrapper">
        <atomics-btn-icon
          id="it"
          [labels]="footerVM.translations?.localization?.italian"
          [svgIcon]="svgIconsConsts.FLAG_ITALY"
          [type]="'button'"
          [types]="['secondary']"
          (clickEmitter)="onChangeLocalization(langConsts.ITALIAN.id)"
        ></atomics-btn-icon>
        <atomics-btn-icon
          id="it"
          [labels]="footerVM.translations?.localization?.english"
          [svgIcon]="svgIconsConsts.FLAG_GB"
          [type]="'button'"
          [types]="['secondary']"
          (clickEmitter)="onChangeLocalization(langConsts.ENGLISH.id)"
        ></atomics-btn-icon>
      </div>
    </div>
  </atomics-footer>
</ng-container>
