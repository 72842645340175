// NgRx
import { createReducer, on, Action } from '@ngrx/store';
// Atomics libs
import { CallState, LoadingState } from '@atomics/common';
// Portfolio MLV Landing (current module)
import { LandingActions, LandingApiActions } from './actions';

export const LANDING_FEATURE_KEY = 'landing';

export interface LandingState {}

export const initialState: LandingState = {};

const landingReducer = createReducer(initialState);

export function reducer(state: LandingState | undefined, action: Action) {
  return landingReducer(state, action);
}
