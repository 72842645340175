export const scopesNames = {
  AUTH: 'auth',
  HOME_MAIN: 'home-main',
  HOME_ROOT: 'home-root',
  HOME_TRANSACTIONS: 'home-transactions',
  LANDING: 'landing',
  SHARED: 'shared',
};

export const scopesNamesObj = {
  AUTH: 'auth',
  HOME_MAIN: 'homeMain',
  HOME_ROOT: 'homeRoot',
  HOME_TRANSACTIONS: 'homeTransactions',
  LANDING: 'landing',
  SHARED: 'shared',
};
