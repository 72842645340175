// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// Atomics libs
import { HttpAdapterService } from '@atomics/adapters';
// Env
import { environment } from '@portfolio-mlv/environments';
// Portfolio MLV Landing (current module)
import { landingApiNames } from '../constants';

@Injectable()
export class LandingApiService {
  constructor(private http: HttpAdapterService) {}
}
