// Angular
import { Injectable } from '@angular/core';
// Atomics libs
import { TranslocoAdapterService } from '@atomics/adapters';
import { DialogLoadingData } from '@atomics/ui';
// Portfolio MLV Core
import {
  uiKeysConsts,
  RootUILoading,
} from '@portfolio-mlv/core/internationalization';

@Injectable()
export class LoadingDialogAdapterService {
  constructor(private translocoAdapterService: TranslocoAdapterService) {}

  toLoadingDialogData(): DialogLoadingData {
    // Get translations we need for the dialog
    const loadingTranslation = this.translateUILoading();

    const dialogLoadingData: DialogLoadingData = {
      disableClose: true,
      size: 'sm',
      message: loadingTranslation.text,
      title: loadingTranslation.text,
    };

    return dialogLoadingData;
  }

  private translateUILoading(): RootUILoading {
    return this.translocoAdapterService.translateObject(uiKeysConsts.LOADING);
  }
}
